export const MuiCheckbox = {
    styleOverrides: {
        colorPrimary: ({ ownerState, theme }) => ({
            // color: theme.palette.grey[300],
            color: theme.palette.zinc[300],
        }),
        // For new tailwind checkbox, if needed
        // root: {
        // 	'.PrivateSwitchBase-input': {
        // 		border: '1px solid rgb(209 213 219)',
        // 		borderRadius: '0.25rem',
        // 	},
        // },
    },
};
