import { Link, styled } from '@mui/material';
export const BackButtonLink = styled(Link)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing(1),
    marginLeft: 'auto',
    gap: theme.spacing(3),
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: '1.25rem',
    color: theme.palette.text.muted,
    ':hover': {
        color: theme.palette.text.medium,
    },
})); // Typescript limitation workaround
