import { jsx as _jsx } from "react/jsx-runtime";
import { Autocomplete, Chip, TextField as MuiTextField } from '@mui/material';
import { t } from 'shared/translations';
import { useState } from 'react';
// type IProps<T> = AutocompletePartialProps<T> & GetQueryDataProps<T> & FormControllerProps;
//const ServerSearchAutocomplete = React.forwardRef(<T, >({ getQuery, onChange, value, labelProperty, isError: isErrorState, ...props }: IProps<T>, ref: React.Ref<T>) => {
/**
 * Sends remote request with search string and fills options with request response.
 *
 * @param multiple forces component to have Chips, otherwise text is used.
 * @param valueProperty
 * @param labelProperty
 * @param getQuery rtk useQuery that makes remote search and returns an array of objects of Q type.
 * @returns an array with string values
 */
const ServerSearchAutocompleteExtended = ({ multiple, valueProperty, labelProperty, isError: isErrorState, getQuery, onChange, ...props }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const { data, isError: isQueryError } = getQuery({ search: searchTerm }, { skip: searchTerm.length < 2 });
    // @ts-ignore
    //const options = useMemo(() => data?.map(value => { value[valueProperty] as (number | string) || [], [data]);
    //console.log('props.value: ', props.value)
    // TODO: доделать для multiple true. (Не доделано).
    return (
    // <Autocomplete<T>
    _jsx(Autocomplete, { 
        // @ts-ignore
        multiple: multiple, 
        //freeSolo // Not to show empty "No options"?
        // @ts-ignore
        freeSolo // Returned to fix Mui console warning about item is not in list (None of the options match with `"LYKMC"`).
        : true, filterSelectedOptions: true, ...props, 
        // @ts-ignore
        //value={ { [valueProperty]: props.value, labelProperty: '' } }
        //value={data?.find(item => item[valueProperty] === props.value) || null}
        //value={data?.find(item => item[valueProperty] === props.value) || (multiple ? undefined : null)}
        value: multiple ? props.value || undefined : data?.find((item) => item[valueProperty] === props.value) || null, clearText: t('delete.all'), options: data || [], 
        //options={options}
        // Fix console error "... `getOptionLabel` method of Autocomplete returned object (BMW) instead of a string ["BMW"] ..."
        // Only reproduced when multiple={false}
        getOptionLabel: (option) => typeof option === 'object' ? option?.[labelProperty] || '' : typeof option === 'string' ? option : '', isOptionEqualToValue: (option, value) => typeof value === 'number' ? option[valueProperty] === value : option[valueProperty] === value[valueProperty], filterOptions: (x) => x, 
        // Multiple forces component to have Chips, otherwise text is used.
        renderTags: multiple
            ? (value, getTagProps, ownerState) => {
                return value?.map((option, index) => {
                    // Key value should be passed within `key` prop only. When it used through spread operator it leads to react console error.
                    const { key, ...tagProps } = getTagProps({ index });
                    return (_jsx(Chip, { variant: "outlined", size: "small", color: "primary", label: option[labelProperty], ...tagProps }, key));
                });
            }
            : undefined, renderInput: (params) => (
        // Only Mui TextFiled works inside Autocomplete.
        _jsx(MuiTextField, { ...params, size: "small", placeholder: props.placeholder ?? t('search.label'), error: isErrorState, sx: multiple ? undefined : { '.MuiOutlinedInput-input': { height: '19px' } } })), onChange: (event, value) => {
            if (multiple) {
                onChange(value);
            }
            else {
                // Handle null value for isDirty to work.
                onChange(value === null ? null : value?.[valueProperty]);
            }
        }, onInputChange: (event, value, reason) => {
            setSearchTerm(value.trim());
        }, noOptionsText: t('please.enter.the.first.two.letters.to.search') }));
};
export { ServerSearchAutocompleteExtended };
