import { PortionState } from 'admin/modules/orders/models';
export function isLastStatus(statusId) {
    const lastStatuses = [
        PortionState.CanceledBySupplier,
        PortionState.SentToCustomer,
        PortionState.CanceledByAdministrator,
        PortionState.CanceledByCustomer,
    ];
    return lastStatuses.includes(statusId);
}
