import { jsx as _jsx } from "react/jsx-runtime";
import { Tooltip } from '@mui/material';
import { shadowSm } from 'core/theme/shadows';
import { useState } from 'react';
/**
 * This tooltip only rendered after the specified action is executed and removed from the DOM right after the specified delay.
 */
export const ActionTooltip = ({ closeDelay = 1000, color = 'default', children, ...rest }) => {
    const [renderTooltip, setRenderTooltip] = useState(false);
    const sxColor = color === 'success'
        ? ({ palette }) => ({
            color: palette.success.dark,
            backgroundColor: palette.success.lightest,
            boxShadow: shadowSm,
        })
        : undefined;
    return (_jsx("div", { onClick: () => {
            if (!renderTooltip)
                setRenderTooltip(true);
            setTimeout(() => setRenderTooltip(false), closeDelay);
        }, children: renderTooltip ? (_jsx(Tooltip, { open: true, disableFocusListener: true, disableHoverListener: true, disableTouchListener: true, ...rest, slotProps: {
                popper: {
                    disablePortal: true,
                },
                tooltip: {
                    sx: sxColor,
                },
            }, children: children })) : (children) }));
};
