export var PortionState;
(function (PortionState) {
    /** `Заказан` */
    PortionState[PortionState["Ordered"] = 1] = "Ordered";
    /** `Отправлен поставщику` */
    PortionState[PortionState["SentToSupplier"] = 2] = "SentToSupplier";
    /** `Подтвержден поставщиком` */
    PortionState[PortionState["ConfirmedBySupplier"] = 4] = "ConfirmedBySupplier";
    /** `Отменен поставщиком` */
    PortionState[PortionState["CanceledBySupplier"] = 5] = "CanceledBySupplier";
    /** `Ожидается на складе GLOBAL PARTS` */
    PortionState[PortionState["ExpectedInStock"] = 6] = "ExpectedInStock";
    /**
     * `Получен`
     *
     * **This status in currently not used.**
     */
    PortionState[PortionState["Received"] = 7] = "Received";
    /** `На складе GLOBAL PARTS` */
    PortionState[PortionState["InTheStock"] = 8] = "InTheStock";
    /** `Отправлен заказчику` */
    PortionState[PortionState["SentToCustomer"] = 9] = "SentToCustomer";
    /** `Отменен администратором` */
    PortionState[PortionState["CanceledByAdministrator"] = 12] = "CanceledByAdministrator";
    /** `Запрошен возврат` */
    PortionState[PortionState["RefundRequested"] = 13] = "RefundRequested";
    /** `Возврат одобрен, ожидается на складе` */
    PortionState[PortionState["RefundApprovedAndExpectedInWarehouse"] = 14] = "RefundApprovedAndExpectedInWarehouse";
    /** `Возврат отклонен` */
    PortionState[PortionState["RefundRejected"] = 15] = "RefundRejected";
    /** `Возврат принят на складе` */
    PortionState[PortionState["RefundAcceptedInStock"] = 16] = "RefundAcceptedInStock";
    /** `Отменен заказчиком` */
    PortionState[PortionState["CanceledByCustomer"] = 17] = "CanceledByCustomer";
    /** `Принят в ПВЗ` */
    PortionState[PortionState["AcceptedAtOPP"] = 18] = "AcceptedAtOPP";
    /** `Передан в службу доставки` */
    PortionState[PortionState["TransferredToDeliveryService"] = 19] = "TransferredToDeliveryService";
    /** `Передан в службу доставки (собств.)` */
    PortionState[PortionState["TransferredToDeliveryServiceOwn"] = 20] = "TransferredToDeliveryServiceOwn";
})(PortionState || (PortionState = {}));
