import { jsx as _jsx } from "react/jsx-runtime";
import { TableCell, TableRow } from 'core/styled';
import { Spinner } from 'core/ui';
import { memo } from 'react';
import { useIntersectionObserver } from 'usehooks-ts';
/**
 *  Should be used in {@link Table} components inside {@link TableFooter}.
 */
const InfiniteScrollSpinner = memo(({ columnCount, itemCount, isLoading, isFetching, isError, isLast, onShown }) => {
    const { ref } = useIntersectionObserver({
        threshold: 0.5,
        onChange: (isIntersecting, entry) => {
            // Without this checks multiple api fetches is executed on page mount.
            if (isIntersecting && !isLoading && !isFetching && !isError && isLast === false) {
                onShown();
            }
        },
    });
    // TODO: to delete this logs later.
    // console.log('isLoading:', isLoading);
    // console.log('isFetching:', isFetching);
    // console.log('isError:', isError);
    // console.log('Boolean(itemCount):', Boolean(itemCount));
    // console.log('isLast:', isLast);
    // This check must not be placed here. onChange will fire multiple times on every rerender.
    // if (isLoading || isFetching || isError || !itemCount || isLast || isLast === undefined) return null;
    return (_jsx(TableRow, { ref: ref, children: _jsx(TableCell, { colSpan: columnCount, sx: sxSpinnerTableCell, children: !isLoading && !isFetching && !isError && Boolean(itemCount) && isLast === false && _jsx(Spinner, {}) }) }));
});
const sxSpinnerTableCell = {
    border: 0,
    padding: '.5rem 0 .5rem !important',
};
export { InfiniteScrollSpinner };
