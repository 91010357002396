import { alpha } from '@mui/material';
export const MuiAvatar = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            // borderRadius: '20%',
            borderRadius: '9999px',
            outlineColor: alpha(theme.palette.common.black, 0.2),
            outlineOffset: '-1px',
            outlineWidth: '1px',
            outlineStyle: 'solid',
            verticalAlign: 'middle',
            // `theme.palette.Avatar.defaultBg` how to set this var?
            backgroundColor: theme.palette.text.muted,
        }),
    },
};
