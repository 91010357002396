import { TableCell as MuiTableCell, styled } from '@mui/material';
import { shouldNotForwardProps } from 'shared/helpers';
/**
 * **Global Parts** `TableCell` component.
 */
export const TableCell = styled(MuiTableCell, shouldNotForwardProps('primary', 'medium'))(({ theme, primary, medium }) => ({
    // TODO: (later) this `color` prop will be deleted when totally moves to `MuiTableCell` theme, as default color for table cells.
    color: theme.palette.text.muted,
    ...(primary && { color: theme.palette.text.primary }),
    ...(medium && { fontWeight: theme.typography.fontWeightMedium }),
}));
