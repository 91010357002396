import * as components from './components';
import { shadows } from './shadows';
import { typography } from './typography';
const themeOptions = {
    //spacing: 4, // 8 is default Mui recommended.
    //spacing: (factor: number) => `${0.25 * factor}rem`, // (Bootstrap strategy)
    // spacing: (factor: number) => `${0.5 * factor}rem`,
    spacing: (factor) => `${0.25 * factor}rem`,
    //spacing: [0, 4, 8, 16, 32, 64],
    // palette: {
    // 	// mycompany: {
    // 	// 	primary: '#003366',
    // 	// },
    // },
    shape: {
        // 6 is now working for buttons and inputs. Paper radius is overridden inside MuiPaper styles.
        borderRadius: 6, // '0.375rem' - tailwind, // 4 - MUI default
    },
    shadows: shadows,
    typography: typography,
    components: components,
};
export { themeOptions };
