import { jsx as _jsx } from "react/jsx-runtime";
import { BreadcrumbSeparator } from './BreadcrumbSeparator';
import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
import { useMatches } from 'react-router-dom';
/**
 * Obsolete! Should be replaced with BreadcrumbsCatalyst.
 *
 * @param param0
 * @returns
 */
const Breadcrumbs = () => {
    const matches = useMatches();
    // Get rid of any matches that don't have handle and crumb.
    // Map them into an array of elements, passing the loader data along with url parameters to each crumb.
    const crumbs = matches
        .filter((match) => Boolean(match.handle?.crumb))
        .map((match) => match.handle.crumb({ loaderData: match.data, params: matches[0].params }));
    return (_jsx(MuiBreadcrumbs, { separator: _jsx(BreadcrumbSeparator, {}), children: crumbs.map((crumb, index) => (_jsx(crumb.type, { ...crumb.props }, index))) }));
};
export { Breadcrumbs };
