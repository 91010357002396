import { jsx as _jsx } from "react/jsx-runtime";
import { shouldNotForwardProps } from 'shared/helpers';
import { styled } from '@mui/material/styles';
const FormBox = styled((props) => _jsx("form", { ...props, onSubmit: (event) => event.preventDefault() }), shouldNotForwardProps('fullHeight', 'sx'))(({ theme, fullHeight }) => ({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    ...(fullHeight && { height: '100%' }),
}));
export { FormBox };
