import { PortionState } from '../models';
import { t } from 'shared/translations';
export const getPortionStateLabel = (stateId, shortened) => {
    const ending = shortened ? '.short' : '';
    switch (stateId) {
        case PortionState.Ordered:
            return t('ordered.label');
        case PortionState.SentToSupplier:
            return t(`sent.to.supplier${ending}`);
        case PortionState.ConfirmedBySupplier:
            return t(`confirmed.by.supplier${ending}`);
        case PortionState.CanceledBySupplier:
            return t(`canceled.by.supplier${ending}`);
        case PortionState.ExpectedInStock:
            return t(`expected.in.stock${ending}`);
        // Not used now.
        case PortionState.Received:
            return t('received.label');
        case PortionState.InTheStock:
            return t(`in.the.stock${ending}`);
        case PortionState.SentToCustomer:
            return t(`sent.to.customer${ending}`);
        case PortionState.CanceledByAdministrator:
            return t(`canceled.by.administrator${ending}`);
        case PortionState.RefundRequested:
            return t(`refund.requested.status`);
        case PortionState.RefundApprovedAndExpectedInWarehouse:
            return t(`refund.approved.and.expected.in.warehouse${ending}`);
        case PortionState.RefundRejected:
            return t(`refund.rejected`);
        case PortionState.RefundAcceptedInStock:
            return t(`refund.accepted.in.stock${ending}`);
        case PortionState.CanceledByCustomer:
            return t(`canceled.by.customer${ending}`);
        case PortionState.AcceptedAtOPP:
            return t(`accepted.at.OPP`);
        case PortionState.TransferredToDeliveryService:
            return t(`transferred.to.delivery.service${ending}`);
        case PortionState.TransferredToDeliveryServiceOwn:
            return t(`transferred.to.delivery.service.own${ending}`);
        default:
            return t(`unknown.status.label`);
    }
};
