import { checkboxClasses, styled, tableCellClasses } from '@mui/material';
import { TableCell } from 'core/styled';
import { shouldNotForwardProps } from 'shared/helpers';
export const TableCellForCheckbox = styled(TableCell, shouldNotForwardProps('stickToLeftEdge'))(({ theme, stickToLeftEdge }) => ({
    [`&.${tableCellClasses.sizeMedium}`]: {
        padding: theme.spacing(0, 1),
        [`.${checkboxClasses.root}`]: {
            paddingTop: '6px',
            paddingBottom: '6px',
            ...(stickToLeftEdge && { paddingLeft: 0 }),
        },
    },
}));
