const core = {
    'moment.locale': 'en',
    $: '$',
    'fa-currency': 'dollar-sign',
    N: 'No.',
    'subsystem.title.adm': 'Admin panel',
    'subsystem.title.wh': 'Warehouse management',
    'subsystem.title.sup': 'Supplier panel',
    'green.color': 'Green',
    'green.color.feminine': 'Green',
    'blue.color': 'Blue',
    'blue.color.feminine': 'Blue',
    'purple.color': 'Purple',
    'purple.color.feminine': 'Purple',
    'orange.color': 'Orange',
    'orange.color.feminine': 'Orange',
    'sign.in.label': 'Sign In',
    'sign.in.noun': 'Sign In',
    //"login.label": "Sign In",
    'login.label': 'Login',
    'registration.label': 'Registration',
    'phone.label': 'Phone',
    'phone.number.label': 'Phone number',
    'enter.password.label': 'Enter password',
    'reset.password.label': 'Reset password',
    'change.password.label': 'Change password',
    'password.change.label': 'Password change',
    'restore.password': 'Restore password',
    'forget.password.label': 'Forget password ?',
    'current.password.label': 'Current password',
    'enter.current.password': 'Enter current password',
    'new.password.label': 'New password',
    'confirm.new.password.label': 'Confirm new password',
    'passwords.do.not.match': 'Passwords do not match',
    'confirm.the.password': 'Confirm the password',
    'current.password.error1': 'Enter your current password (6 to 10 characters)',
    'new.password.error1': 'Enter a new password (at least 6 characters)',
    'at.least.6.characters': 'at least 6 characters',
    'at.least.number.characters': 'at least {1} characters',
    'enter.realphone.label': 'Enter your actual phone number',
    'enter.your.email': 'Enter your email',
    'enter.email.while.register': 'Enter the email address provided during registration',
    'enter.your.password.recovery.email': 'Enter your Password Recovery Email',
    'enter.code.from.sms': 'Enter code from SMS',
    'change.phone.number.label': 'Change phone number',
    'send.confirmation.code': 'Send confirmation code',
    'confirm.label': 'Confirm',
    'cancel.label': 'Cancel',
    'close.label': 'Close',
    'submit.label': 'Submit',
    'accept.label': 'Accept',
    'delete.label': 'Delete',
    'back.label': 'Back',
    'remove.away.label': 'Remove',
    'choose.label': 'Choose',
    'edit.label': 'Edit',
    'edit.sm.label': 'Edit',
    'save.label': 'Save',
    'change.label': 'Change',
    'change.2.label': 'Change',
    'reset.label': 'Reset',
    'check.label': 'Check',
    'find.label': 'Find',
    'email.label': 'Email',
    'email.sm.label': 'Email',
    'password.label': 'Password',
    'domain.label': 'Domain',
    'sending.label': 'Sending',
    'loading.label': 'Loading',
    'view.label': 'View',
    'create.label': 'Create',
    'refresh.label': 'Refresh',
    'update.label': 'Update',
    'disable.label': 'Disable',
    'enable.label': 'Enable',
    'download.label': 'Download',
    'browse.label': 'Browse',
    'copy.label': 'Copy',
    'settings.label': 'Settings',
    'userid.label': 'User ID',
    'balance.label': 'Balance',
    'sign.out.label': 'Sign out',
    'summary.label': 'Total',
    'file.label': 'File',
    'total.label': 'Total',
    'total.label.currency': 'Total, $',
    'date.label': 'Date',
    'operation.label': 'Operation',
    'return.noun': 'Return',
    'reason.label': 'Reason',
    'reason.base.label': 'Reason',
    'wait.label': 'Wait',
    'action.label': 'Action',
    'available.label': 'Available',
    'not.available.label': 'Not available',
    'full.name.abbr': 'Full name',
    'sex.label': 'Sex',
    'male.sex.label': 'Male',
    'female.sex.label': 'Female',
    'last.name.label': 'Last name',
    'first.name.label': 'First name',
    'middle.name.label': 'Middle name',
    'export.to.label': 'Export to',
    'search.label': 'Search',
    'period.label': 'Period',
    'arbitrarily.label': 'Arbitrarily',
    'days.label': 'Days',
    'days.label.lowercase': 'days',
    'id.label': 'ID',
    'name.label': 'Name',
    'name.lg.label': 'Name',
    //"title.label": "Title",
    'name.md.label': 'Name',
    'description.label': 'Description',
    'comment.label': 'Comment',
    'supplier.label': 'Supplier',
    'manufacturer.label': 'Manufacturer',
    'client.label': 'Client',
    'customer.label': 'Customer',
    'delivery.time.short': 'Delivery',
    'qty.short': 'Qty',
    'qty.short.comma.pcs': 'Qty, pcs.',
    'price.label': 'Price',
    'price.comma.currency': 'Price, $',
    'price.for.one': 'Price for one',
    'selling.price': 'Selling price',
    'selling.price.comma.currency': 'Selling price, $',
    'sum.label': 'Sum',
    'sum.comma.currency': 'Sum, $',
    'sum-amount.label': 'Amount',
    'sum-amount.comma.currency': 'Amount, $',
    'cost.price': 'Cost price',
    'cost.price.comma.currency': 'Cost price, $',
    'margin.label': 'Margin',
    'margin.comma.percent': 'Margin, %',
    'activation.label': 'Activation',
    'time.comma.days': 'Time, d.',
    'availability.comma.pieces': 'Availability, pcs.',
    // Security block
    'token.label': 'Token',
    'access.label': 'Access',
    'access.denied.label': 'Access is denied',
    'user.does.not.have.enough.access.rights': 'The user does not have sufficient rights to access the requested data',
    'you.dont.have.permission.to.access.the.contents.of.this.page': "You don't have permission to access the contents of this page.",
    'go.to.the.main.page': 'Go to the Main page',
    'no.data.label': 'No data',
    'order.no.label': 'Order No.',
    'order.id.label': 'Order ID',
    'order.date.label': 'Order date',
    'status.label': 'Status',
    'purchase.label': 'Purchase',
    'selling.label': 'Selling',
    'counterparty.label': 'Counterparty',
    'to.pay.label': 'To pay',
    'paid.label': 'Paid',
    'we.have.to.pay.sum.cur': 'We have to pay, sum, $',
    'we.must.be.paid.sum.cur': 'We must be paid, sum, $',
    'document.label': 'Document',
    'deferment.label': 'Deferment',
    'counterparty.total.debt': 'Counterparty total debt',
    'amounts.of.payments.entire': 'Amounts of payments for the entire period',
    'payments.today': 'Payments today',
    'advance.payment': 'Advance',
    'order.amount': 'Order amount',
    'unshipped.orders.amount': 'Unshipped orders amount',
    'planned.label.plural': 'Planned',
    'planned.label.feminine': 'Planned',
    'overdue.feminine': 'Overdue',
    'counterparty.name.lg': 'Counterparty name',
    'type.label': 'Type',
    'collected.label': 'Collected',
    'created.label': 'Created',
    'place.label': 'Place',
    'places.label': 'Places',
    'vendor.code': 'Vendor code',
    'pricelist.label': 'Price list',
    'amount.label': 'Количество',
    'cash.label': 'Cash',
    'pick.up.label': 'Pick up',
    'giveawayWithoutPincode.label': 'Give away without pincode',
    'universal.transmission.document': 'Universal transmission document',
    'expand.menu.label': 'expand.menu',
    'collapse.menu.label': 'collapse.menu',
    'file.type.label': 'File type',
    'utd.abbr': 'UTD',
    'legal.abbr': 'Legal',
    'pieces.abbr': 'pc.', // "pcs"
    'days.abbr': 'days.',
    'hours.abbr': 'h.',
    'qrcode.abbr': 'QR',
    'city.abbr': '',
    'house.abbr': 'h.',
    'street.abbr': 'st.',
    'apartment.abbr': 'apt.',
    'vat.abbr': 'VAT',
    'in.preposition': 'in',
    'from.preposition': 'from',
    'to.preposition': 'to',
    'dated.label': 'dated',
    'display.list': 'List',
    'display.as.list': 'List',
    'display.by.cells': 'By cells',
    'display.by.customers': 'By customers',
    'display.by.suppliers': 'By suppliers',
    'display.by.counterparties': 'By counterparties',
    'in.work.status': 'In work',
    'canceled.status': 'Canceled',
    'ready.status': 'Ready',
    'archive.status': 'Archive',
    'archive.label': 'Archive',
    'canceling.status': 'Canceling',
    'refund.requested.status': 'Refund requested',
    'issued.status': 'Issued',
    'expected.in.stock': 'Expected in stock',
    'taken.in.stock': 'Taken in stock',
    'cancel.the.order': 'Cancel the order',
    'make.a.refund': 'Make a refund',
    're-order.label': 'Re-order',
    'to.archive.label': 'To archive',
    'take.label': 'Take',
    'refuse.label': 'Refuse',
    'more.details.label': 'More details',
    'add.comment.label': 'Add comment',
    'enter.comment': 'Enter comment',
    'deletion.confirmation.label': 'Deletion confirmation',
    'deletion.label': 'Delete',
    'u.sure.delete.label': 'Are you sure you want to delete',
    'personal.label': 'Personal',
    'legals.label': 'Legals',
    'legal.label': 'Legal',
    'position.label': 'Position',
    'logo.label': 'Logo',
    'choosefile.label.droparea': 'Choose file',
    'ordrop.label.droparea': 'or drop it here',
    'format.less1mb.label.droparea': 'Format png, gif, jpg. Size up to 1Mb.',
    'no.more.than.count.pcs': 'No more than {1} pcs.',
    'personal.data.label': 'Personal data',
    'primary.data.label': 'Primary data',
    'birthday.date.label': 'Birthday',
    'roles.label': 'Roles',
    'no.roles.assigned': 'No roles assigned',
    'data.ready.to.send': 'Data ready to send',
    'companies.label': 'Companies',
    'legal.type': 'Legal entity type',
    'legal.type.short': 'Legal type',
    'add.company.label': 'Add company',
    'edit.company': 'Edit company',
    'addresses.label': 'Addresses',
    'delivery.addresses': 'Delivery addresses',
    'add.address': 'Add address',
    'add.email': 'Add email',
    'linked.accounts.str': 'Linked accounts in social networks',
    'unlink.social.network.qu': 'Unlink a social network ?',
    'subscribe.to.notifications': 'Subscribe to notifications',
    'notification.time': 'Notification time',
    'for.documents.label': 'For documents',
    'order.notifications': 'Order notifications',
    'from.an.individual': 'From an individual',
    'on.behalf.of.company': 'On behalf of',
    'which.company.to.place.an.order.for': 'Which company to place an order for ?',
    'delivery.address': 'Delivery address',
    'locality.label': 'Locality',
    'street.label': 'Street',
    'house.label': 'House',
    'house.block.label': 'Block',
    'house.building.label': 'Building',
    'apartment.slash.office': 'Apartment / office',
    'contract.label': 'Contract',
    'contract.no': 'Contract No.',
    'terms.of.use': 'Terms of use',
    'terms.of.use.instrumental.case': 'Terms of use',
    'feedback.label': 'Feedback',
    'offer.out.of.date': 'Offer is out of date',
    'find.another.offer': 'Find another offer',
    'no.payment.found': 'No payment found',
    'change.search.and.try.again': 'Change your search criteria and try again',
    'your.name.label': 'Your name',
    'message.label': 'Message',
    'order.label': 'Order',
    'order.num.label': 'Order No.',
    'order.genitive.lower': 'order',
    'return.noun.genitive.lower': 'return',
    'peaces.available.for.order': 'Only {2} pcs available for {1}',
    'by.default': 'by default',
    'use.by.default': 'use by default',
    'remember.my.choice': 'remember my choice',
    'goods.label': 'Goods',
    'delivery.label': 'Delivery',
    'free.label': 'free',
    'agree.with': 'Agree with ',
    'pay.order': 'Pay order',
    'change.shipping.method': 'Change shipping method',
    'delivery.point': 'Delivery point',
    'delivery.point.abbr': 'Delivery point',
    'delivery.point.all': 'All delivery points',
    'select.delivery.method': 'Select delivery method',
    'pick-up.point': 'Pick-up point',
    'select.placeholder': 'Select...',
    'pick.a.date.placeholder': 'Pick a date',
    'no.options.dropdown.label': 'No options',
    'city.label': 'City',
    'open.label': 'Open',
    'closed.label': 'Closed',
    'changes.saved.successfully': 'Changes saved successfully',
    'error.saving ': 'Error saving',
    'error.loading.data': 'Error loading data',
    'manually.label': 'Manually',
    'server.search.autocomplete.simple.new.value.add.prefix': 'Add',
    'confirm.the.value.by.pressing.enter': 'Confirm the value by pressing enter',
    'enter.values.and.press.enter.to.confirm': 'Enter values and press enter to confirm',
    'enter.time.in.format': `Enter time in format '12:00', '1200' or 12`,
    'enter.time.in.format.and.press.enter': `Enter time in format '12:00', '1200' или 12 and press enter to approve.`,
    // react-day-picker
    'move.focus.to.the.previous.week': 'Move focus to the previous week',
    'move.focus.to.the.next.day': 'Move focus to the next day',
    'move.focus.to.the.next.week': 'Move focus to the next week',
    'move.focus.to.the.previous.day': 'Move focus to the previous day',
    'move.focus.to.the.previous.month': 'Move focus to the previous month',
    'move.focus.to.the.next.month': 'Move focus to the next month',
    'move.focus.to.the.previous.year': 'Move focus to the previous year',
    'move.focus.to.the.next.year': 'Move focus to the next year',
    'move.focus.to.the.start.of.the.week': 'Move focus to the start of the week',
    'move.focus.to.the.end.of.the.week': 'Move focus to the end of the week',
    'select.the.focused.day': 'Select the focused day',
    'date.rage.picker.shortcut.reset': 'Reset',
    'date.rage.picker.shortcut.previous.week': 'Previous Week',
    'date.rage.picker.shortcut.this.week': 'This Week',
    'date.rage.picker.shortcut.last.week': 'Last Week',
    'date.rage.picker.shortcut.next.week': 'Next Week',
    'date.rage.picker.shortcut.previous.month': 'Previous Month',
    'date.rage.picker.shortcut.current.month': 'Current Month',
    'date.rage.picker.shortcut.last.month': 'Last Month',
    'date.rage.picker.shortcut.next.month': 'Next Month',
};
const adm = {
    'orders.section': 'Orders',
    'clients.section': 'Clients',
    'suppliers.section': 'Suppliers',
    'pricelists.section': 'Price lists',
    'reclamations.section': 'Reclamations',
    'reports.section': 'Reports',
    'reports-sup.section': 'Suppliers reports',
    'reports-sup.breadcrumb': 'Suppliers report',
    'reports-clients.section': 'Clients reports',
    'reports-payments.section': 'Payment calendar',
    'settings.section': 'Settings',
    'userprofile.section': 'User profile',
    'role.editing.label': 'Role editing',
    'role.creation.label': 'Role creation',
    'user.editing.label': 'User data editing',
    'user.creation.label': 'User creation',
    'cells.creation.label': 'Create cells',
    'stock.editing.label': 'Stock editing',
    'warehouse.creation.label': 'Warehouse creation',
    'pricelist.creation.label': 'Price list creation',
    'pricelist.editing.label': 'Price list editing',
    'customer.settings.label': 'Customer settings',
    'adding.rule.label': 'Adding a rule',
    'general.settings.btn': 'General',
    'general.settings.desc': 'View and change data about your store',
    'payments.settings.btn': 'Payment systems',
    'payments.settings.desc': 'Provider payment management',
    'warehouses.label': 'Warehouses',
    'warehouses.settings.desc': 'Own availability management',
    'brands.settings.btn': 'Brands and crosses',
    'brands.settings.desc': 'Brands and articles creation and management',
    'notifications.settings.btn': 'Notifications',
    'notifications.settings.desc': 'Customize messages for you and your customers',
    'warehouse.addresses.label': 'Warehouse addresses',
    'warehouse.addresses.settings.desc': 'Cell addresses configuration and management',
    'users.settings.btn': 'Roles and users',
    'users.settings.desc': 'Configuration and management',
    //"terms.settings.btn": "Terms of Use",
    'terms.settings.desc': 'Legal conditions, offer and return policy',
    'terms.settings.fulldesc': `This is your offer or contract, which is accepted by each client and visitor of your site. You can change or supplement this text at any time.`,
    'language.settings.btn': 'UI language',
    'language.settings.desc': 'Linguistic locations creation and management',
    // Suppliers.
    'supplier.logo': 'Supplier logo',
    'generate.logo': 'Generate logo',
    'price.list.primary.data': 'Price list primary data',
    'unique.logo.desc': `The unique logo of the price list will be displayed in the search results on the site.`,
    'logo.is.busy.or.small': 'Logo is busy or contains less than 4 characters',
    'price.list.internal.name': 'The internal name of the price list is available only to you.',
    'price.list.name.placeholder': 'My first price list',
    'delivery.time.desc': 'The delivery time in days to the central warehouse, buyers see this delivery time on the site.',
    'price.list.currency': 'Price list currency',
    'exchange.rate': 'Exchange rate',
    'central.bank': 'Central bank',
    'central.bank.abbr': 'CB',
    'own.label': 'Own',
    'price.list.settings': 'Price list settings',
    'price.download.method.desc': 'Way to load price list.',
    'price.list.file.encoding.label': 'File encoding.',
    'price.list.file.encoding.desc': 'File encoding.',
    'price.list.file.type.desc': 'Choose the type of price list.',
    'price.list.start': 'Price list start',
    'price.list.start.desc': 'The line number with which the offer starts in the price list.',
    'line.number.lower': 'line number',
    'delimiter.label': 'Delimiter',
    'tabulation.lower': 'tabulation',
    'another.lower': 'another',
    'mail.for.orders.desc': 'Mail for receiving orders for the current price list',
    'time.of.sending.description': 'Time to receive orders, Moscow time.',
    'mail.address': 'Mail address',
    'mail.address.for.send': 'Indicate the mail with which you will send the price list.',
    'file.name.lg.desc': `Specify the exact file name with the extension. It is allowed to use the symbol “*” to indicate the variable part in the name of the price list. For example, the name of the price list is Price030317.xlsx, where 030317 is the date designation (variable part), in this case it is worth specifying the name Price*.xlsx.`,
    'letter.subject.desc': `For the correct identification of the price list, indicate the subject of the letter. You can use the symbol "*" to indicate the variable part of the subject line. For example, the subject of the letter is Price list dated 03.03 availability, then you must specify the value - Price list from * availability.`,
    'price.list.settings.letter.subject.text.field.placeholder': 'price list for download by email',
    //"port.desc": `Укажите порт для подключения, если вы не понимаете о чем речь, попробуйте оставить текущее значение, или задайте вопрос вашему администратору.`,
    'price.list.column.numbers': 'Price list column numbers',
    'price.list.column.numbers.desc': 'Indicate the column numbers in your price list.',
    'partionism.label': 'Partionism',
    'partionism.tooltip.desc': 'Optional column. Allows you to configure the minimum number of pieces for sale.',
    'fixed.brand': 'Fixed brand',
    'brand.placeholder': 'Enter brand',
    'update.date': 'Update date',
    'supplier.is.own.legal': 'Supplier - own legal entity',
    'accept.returns': 'Accept returns',
    'accounting.label': 'Accounting',
    'edit.supplier.pricelist.label': 'Edit supplier price list',
    'second.login.request.failed': 'Supplier panel sign in failed',
    // Price lists.
    'price.lists.label': 'Price lists',
    'create.price.lists': 'Create price lists',
    'format.label': 'Format',
    'schedule.label': 'Schedule',
    'total.margin.comma.percent': 'Total margin, %',
    'markup.label': 'Markup',
    'pricelists.and.margins.cp': 'Price lists and their margins,%',
    'updated.label': 'Updated',
    'generate.label': 'Generate',
    'is.being.generated': 'Is being generated',
    'match.label': 'Match',
    'unrecognized.stamps': 'Unrecognized stamps',
    'compare.stamps.with.our.db': 'Compare stamps from your price list with stamps from our database',
    'not.recognized.brands': 'Not recognized brands',
    'price.list.name': 'Price list name',
    'letter.subject.label': 'Letter subject',
    //"shipping.addresses": "Shipping addresses",
    'sending.methods': 'Sending methods',
    'emails.to.send': 'Emails to send',
    'ftp.to.send': 'FTP to send',
    'ftp.label': 'FTP',
    'list.addresses.with.space': 'List addresses with a space',
    'pack.to.archive': 'Pack to archive',
    'on.weekdays': 'On weekdays',
    'everyday.label': 'Everyday',
    'how.often.send.orders': 'How often to send orders',
    'list.hours.by.space': 'List the hours separated by a space',
    'ftp.connected.successfully': 'FTP connected successfully',
    'directory.path': 'Directory path',
    'pricelist.save.fail': 'Error saving price list',
    'pricelist.save.success': 'Price list saved successfully',
    'exclude.brands': 'Exclude brands',
    'exclude.articles': 'Exclude articles',
    'encoding.label': 'Encoding',
    // Reclamations.
    'no.reclamations': 'No reclamations',
    'create.a.return.task': 'Create a return task',
    // General settings.
    'domain.desc.1': `To add a domain, you must specify our DNS records in the admin panel of the domain registrar:`,
    'domain.desc.2': `After adding entries, enter your domain in the field and click on the "Check" button. If the entries are correct, your domain will be saved successfully.`,
    'enter.domain.label': 'Enter domain',
    'success.domain.check.response': 'Domain successfully added',
    'fail.domain.check.response': 'Fail',
    'site.name.label': 'Site name', // to core?
    'appearance.label': 'Appearance',
    'appearance.desc': `Enter a site name or upload a logo. Choose a site color scheme.`,
    'color.scheme.label': 'Color scheme',
    'contacts.label': 'Contacts',
    'contact.details.label': 'Contact details',
    'contact.details.desc': `To contact you with your customers, suppliers and portal administration.`,
    'customers.phone.label': 'Contact phone number for customers',
    'inbox.mail.label': 'Inbox mail',
    'outgoing.mail.label': 'Outgoing Mail',
    'from.customers.label': 'From customers',
    'from.administration.label': 'From the administration',
    'with.notifications.label': 'With notifications',
    'with.mailing.orders.label': 'With mailing orders',
    'service.settings.label': 'Service Settings',
    'service.settings.desc': 'Mailing addresses for the main services of the system. The system will not work without them.',
    'for.sup.pricelists.label': 'For price lists from suppliers',
    'for.sup.invoices.label': 'For invoices from suppliers',
    'for.customers.orders.label': 'For orders from customers',
    'for.sup.answers.label': 'For answers from suppliers',
    'success.email.check.response': 'Settings valid',
    'fail.email.check.response': 'Settings invalid(необходим перевод)', // TODO: to delete ?
    'Login or password is wrong': 'Login or password is wrong',
    'client.types.label': 'Customers types',
    'client.types.desc': 'To create a registration form on your site for all the necessary types of customers you work with.',
    'legal.desc': 'You can create several legal entities for the convenience of working with clients.',
    'add.legal.label': 'Add legal',
    'remove.legal.default.warning': 'Before deleting this legal entity, set another one by default.',
    'managing.order.issuance.desc': 'Managing order issuance',
    // Roles and Users
    'u.sure.delete.role': 'Are you sure you want to delete the role',
    'u.sure.delete.user': 'Are you sure you want to delete the user',
    'password.reset.confirmation': 'Password reset confirmation',
    'u.sure.reset.user.password': 'Are you sure you want to reset the password for the user',
    'u.sure.unblock.user': 'Are you sure you want to unblock the user',
    'username.label': 'Username',
    'unlock.in.minutes': 'Unlock in (minutes)',
    'user.activation': 'User Activation',
    'user.lockout': 'User lockout',
    'users.label': 'Users',
    'create.user': 'Create user',
    'create.role': 'Create role',
    'enter.role.name': 'Enter a role name',
    'enter.role.name.desc': 'It will be displayed during authentication and when creating users',
    'choose.accesses': 'Choose accesses',
    'choose.one.several.all': 'You can choose one, several or all.',
    'no.accesses.available': 'No accesses available',
    'primary.data.desc': `To create a user, you need his name, date of birth and email address (to which the password will be sent)`,
    'choose.role.label': 'Choose a role',
    'choose.role.desc': `Choose a role or more. Access to different applications will depend on the choice. So, for example, the Storekeeper role has access only to WMS, users with this role will not be able to log into the admin panel.`,
    'upload.photo': 'Upload photo',
    'upload.user.photo.desc': 'You can upload a real employee photo or avatar',
    'user.comment.desc': 'Here you can enter any additional information about the user',
    'article.search.results': 'Article Search Results',
    'select.delivery.points': 'Select delivery points...',
    'user.password.reset.successfully': 'User password reset successfully',
    'user.password.reset.fail': 'Password reset error',
    // Settings -> Storage, Stock
    'no.sectors.created.yet': 'No sectors created yet',
    'deleting.sector.not.possible': 'Deleting a sector is not possible if there is stock in it.',
    'u.sure.delete.sector': 'Are you sure you want to delete the sector',
    'specify.type': 'Specify type',
    'stock.type.desc': 'Stock is your own stock',
    'transfer.type.desc': 'Transfer - goods in storage, not yet accepted for sale',
    'crossdocking.type.desc': 'Crossdocking - transit warehouse',
    'stock.cells': 'Stock cells',
    'crossdocking.cells': 'Crossdocking cells',
    'transfer.cells': 'Transfer cells',
    'storage.primary.data.desc': `Select a sector, specify a range of cells, you can specify manually or using the grid. To do this, hold down the left mouse button in the cell and select the required number, just like in Excel. Horizontal number of cells, vertical number of floors.`,
    'create.warehouse': 'Create a warehouse',
    'choose.stock.desc': 'Choose Stock for storage and sale of goods',
    'choose.transfer.desc': 'Choose Transfer if your product is in transit or has not yet been accepted for sale',
    'create.new.stock': 'Create a new stock',
    'create.new.crossdocking': 'Create a new crossdocking',
    'create.new.transfer': 'Create a new transfer',
    'come.up.unique.stock.name': `Come up with a unique name for the stock and select the addresses in the warehouse where the goods will be stored.`,
    'latin.letters.only.translit': 'Latin letters only',
    'cell.range': 'Cell range',
    'add.range': 'Add range',
    'price.generation.label': 'Price generation',
    'price.generation.desc': `Specify FTP to generate the price. Products from the stock will be updated 1 time per minute and added as a file on FTP`,
    'ftp.address': 'FTP Address',
    'no.addresses.created.for.stocktype': 'No addresses were created for {1}s.',
    // Payments
    'payment.method.connected.successfully': 'Payment method connected successfully',
    'you.can.find.info.on.official': 'You can get acquainted with tariffs and services on the official website',
    'following.payments.available': 'The following payment methods will be available after activation',
    'payments.available.desc': 'Cards (VISA, Mastercard, Maestro), Sberbank Online, Qiwi-wallet, Yandex.Money',
    'gateway.name': 'Gateway name',
    'gateway.password': 'Gateway password',
    'paypal.info': 'Send, shop and pay your way with one account',
    'paypal.info.desc': `PayPal - The largest debit electronic payment system. Allows customers to pay bills and purchases, send and receive money transfers.`,
    'bank.transfer': 'Bank transfer',
    'legal.account.transfer.label': 'Transfer to the current account of a legal entity',
    'legal.account.transfer.desc': `The legal entity you have selected on the customer’s card or the legal entity indicated by default on the Settings page will be presented as the recipient. The client will be able to download the invoice in PDF format.`,
    'cash.payment': 'Cash payment',
    'cash.payment.store.or.courier': 'Cash payment at the store or courier',
    'cash.payment.store.or.courier.desc': 'This option is informational and does not require integration.',
    'no.reports.found': 'No reports found',
    'calendar.label': 'Calendar',
    'list.label': 'List',
    'send.CRF.receipt.info': 'send information for CRF checks',
    // Orders
    'order.filters': 'Order filters',
    'orders.id.label': 'Orders ID',
    'vendors.code': 'Vendors code',
    'number.of.items.sold.pieces': 'Number of items sold, pcs.',
    'apply.label': 'Apply',
    'show-up.label': 'Show-up',
    'statistics.label': 'Statistics',
    'delivery.date': 'Delivery date',
    //'change.status': 'Change status',
    'order.portion.status': 'Order portion status',
    'current.status': 'Current status',
    'change.status.to': 'Change status to:',
    'line.are.loaded.by': 'Line are loaded by',
    'get.order.request.failed': 'Get order request failed',
    //Status
    'ordered.label': 'Ordered',
    'sent.to.supplier': 'Sent to supplier',
    'confirmed.by.supplier': 'Confirmed by supplier',
    'sent.to.customer': 'Sent to customer',
    'received.label': 'Received',
    'canceled.by.supplier': 'Canceled by supplier',
    'canceled.by.administrator': 'Canceled by administrator',
    'stay.at.supplier': 'Stay at supplier',
    'in.transit': 'In transit',
    'ready.to.issue': 'Ready to issue',
    // Customers
    'edit.client.settings': 'Edit client settings',
    'internal.name': 'Internal name',
    'registration.date': 'Registration date',
    'balance.and.limit': 'Balance/Limit',
    'enter.to.the.site': 'Enter to the site',
    'guest.mark-up': 'Guest mark-up',
    'name.of.the.price.list': 'Name of the price list',
    'personal.margin': 'Personal margin',
    'connected.to.the.site': 'Connected to the site',
    'connected.to.the.client': 'Connected to the client',
    'personal.mark-up.on.the.price.list': 'Personal mark-up on the price list',
    'orders.from.file': 'Orders from a file',
    'add.rule.label': 'Add a rule',
    'update.customer.notify.schedule.request.failed': 'Update notify schedule request failed',
    'email.and.sms.notification.schedule': 'Email and sms notification schedule',
    'notification.schedule': 'Notifications schedule',
    'notification.schedule.has.been.successfully.updated': 'Notification schedule has been updated',
    'customer.email.has.been.successfully.deleted': 'Customer email has been deleted',
    'beginning.of.file': 'Beginning of file',
    'email.for.send': 'Email for send',
    'email.for.reply': 'Email for reply',
    'no.email.addresses.yet': 'There are no email addresses yet',
    'deleting.email.header': 'Deleting Email',
    'email.{1}.will.be.removed.confirmation.text': 'Email "{1}" will be permanently deleted.',
    'delete.customer.email.request.failed': 'Delete customer email request failed.',
    'column.settings': 'Column settings',
    'order.settings': 'Order settings',
    'exact.match': 'exact match',
    'digression.in.percent': 'digression in %',
    'to.order.label': 'To order',
    'total.quantity.from.order': 'Total quantity from order',
    'partial.quantity': 'partial quantity',
    'price.lists.for.order': 'Price lists for the order',
    'mark-up.on.price.lists': 'Mark-up on price lists, %',
    'delete.rule.label': 'Delete a rule?',
    'question.delete.label': 'Want to delete?',
    'add.id.label': 'Add the ID',
    'payment.from.the.company.{1}': 'Payments from the company "{1}"',
    'payment.on.behalf.of.the.company': 'Payment on behalf of the company',
    //Language
    'create.language': 'Create language',
    'edit.language': 'Edit language',
    'language.name.label': 'Name',
    'active.label': 'Active',
    'payment.deferment.comma.days': 'Payment deferment, days',
    'trade.on.behalf.of': 'Trade on behalf of',
    'minimum.shipment.amount': 'Minimum shipment amount',
    'shipment.schedule': 'Shipment schedule',
    //Delivery point
    'create.delivery.point': 'Create delivery point',
    'edit.delivery.point': 'Edit delivery point',
    'not.created.delivery.point': 'You have not yet created a single delivery point',
    'own.delivery.point': 'Own delivery point',
    'partnership.delivery.point': 'Partnership delivery point',
    'delivery.type.label': 'Type of delivery',
    'delivery.type.dis': 'Choose what kind of delivery point you want to create',
    'partnership.label': 'Partnership',
    'own.delivery.label': 'Own',
    'qty.delivery.label': 'Number of points of delivery.',
    'name.delivery.label': 'Name of the delivery',
    'delivery.name.dis': 'Internal name, available only for You',
    'delivery.contact.information.dis': 'Enter the delivery point address, contact information, and work schedule.',
    'cost.of.delivery.label': 'Cost of delivery',
    'delivery.set.delivery.cost.dis': 'Set up the delivery cost for this point.',
    'fixed.cost.label': 'Fixed cost',
    'mark-up.from.order.amount.label': '% from the order amount',
    'order.amount.less.than.label': 'the order amount is less than',
    'if.label': 'if',
    'delivery.point.successfully.connected': 'The delivery point is successfully connected',
    'max.price': 'More expensive than',
    'min.price': 'Cheaper than',
    //days name
    'mo.label': 'MO',
    'tu.label': 'TU',
    'we.label': 'WE',
    'th.label': 'TH',
    'fr.label': 'FR',
    'sa.label': 'SA',
    'su.label': 'SU',
    'dayOff.label': 'Day off',
};
const main = {
    'our.results.label': 'Our results',
    'maintenance.parts.label': 'Selection of spare parts for maintenance',
    'parts.quantity.label': 'Spare parts online',
    'orders.today.label': 'Orders today',
    'search.queries.label': 'Search query',
    'enter.phone.email.desc': 'Enter the phone number or email address provided during registration',
    'phone.or.email.label': 'Phone number or email',
    'recipient.fullName.label': 'recipient full name',
    'personal.area.label': 'Personal Area',
    'enter.detail.number': 'Enter part number',
    'for.suppliers.label': 'To suppliers',
    'refund.policy': 'Refund policy',
    'refund.policy.desc': `A separate part of the offer regarding the policy of returns. It is placed in a separate block specifically so that your customers can get acquainted with such an important aspect of cooperation.`,
    'contact.us.label': 'Contact us',
    'cart.label': 'Cart',
    'cart.is.empty': 'Cart is empty',
    'total.items': 'Total items',
    'total.cost': 'Total cost',
    'goto.checkout': 'Go to checkout',
    'yout.cart.empty.now': 'Your cart is currently empty',
    'use.search.to.find': 'Use the search to find the products you are interested in',
    'adding.item.to.cart': 'Adding item to cart',
    'add.to.cart': 'Add to cart',
    'total.items.in.your.cart': 'Total items in your cart',
    'create.return.request': 'Create a return request',
    'to.place.an.order.pay': 'To place an order, pay',
    'to.place.an.order.pay.extra': 'To place an order, pay extra',
    'continue.shopping': 'Continue shopping',
    'order.successfully.placed': 'Order successfully placed',
    'monitor.order.status.left-part': 'In the ',
    'monitor.order.status.right-part': ' section, you can monitor its status',
    'pay.on.credit': 'Pay on credit',
    'write.off.from.balance': 'Write off from the balance',
    'pay.with.balance': 'Pay with balance',
    'you.can.use.credit.limit': 'You can use a personal credit limit to pay for the order.',
    'not.enough.money.to.pay': 'There is not enough money in your account to pay for the order.',
    'order.will.go.to.work.instantly': 'The order will go to work instantly',
    'order.will.go.to.work.after': 'The order will go to work only after receiving the funds',
    'price.may.change.during.this.time': 'Price may change during this time',
    'bank.card': 'Bank card',
    'yandex.money': 'Yandex.money',
    'from.legal.entity.account': 'From the current account of a legal entity',
    'you.can.pay.in.cash': 'You can pay in cash at the store',
    'current.balance.label': 'Current balance',
    'recharge.label': 'Recharge',
    'recharge.balance': 'Recharge balance',
    'balance.recharge': 'Balance recharge',
    'credit.limit.label': 'Credit limit',
    'accounting.for.label': 'Accounting for',
    'enter.amount': 'Enter amount',
    'order.payment.label': 'Order payment',
    'tracking.number.abbr': 'Tracking No.',
    'track.order': 'Track order',
    'receive.sms.notifications': 'Receive SMS notifications',
    'receive.email.notifications': 'Receive Email notifications',
    'your.message.has.been.sent': 'Your message has been sent',
    'we.will.answer.you.soon': 'We will try to answer you as soon as possible',
    'contact.us': 'Contact us',
    'you.can.write.any.question': `You can write to us for any question. We will try to answer you as soon as possible.`,
    'your.return.request.will.be.sent': 'Your return request {1} to {2} managers. Average review time is 3-5 business days.',
    'will.be.sent': 'will be sent',
    'has.been.sent': 'has been sent',
    'order.has.been.canceled': 'Order No. {1} has been canceled',
    'funds.credited.to.balance': 'Funds credited to your balance',
    'refund.approved': 'Refund Approved',
    'return.accepted.balance.replenished': 'Your return has been accepted at the warehouse, your account balance has been replenished',
    'refund.accepted.in.stock': 'Return accepted in stock',
    'refund.rejected': 'Refund rejected',
    'send.return.to.warehouse.etc': `Please send a return to the {1} warehouse to receive the funds. The balance of your account will be replenished automatically right after the return to the warehouse.`,
    'specify.reason.for.return': 'Specify the reason for the return',
    'reason.for.return': 'Reason for return',
    'maximum.characters.count': '{1} characters maximum',
    'product.photos': 'Product Photos',
    'choose.a.photo': 'Choose a photo',
    'searched.article': 'Searched article',
    'sought.label': 'Sought',
    'analogue.label': 'Analogue',
    'analogs.label': 'Analogs',
    'no.results.found.for.request': 'No results were found for your request {1}',
    'no.results.found.for.request.part1': 'No results were found for your request',
    'no.results.found.for.request.part2': '',
    'maybe.you.mean': 'Maybe you mean',
    'several.positions.found': 'Several different positions found by your request!',
    'no.offers': 'No offers',
    'order.cancel.fail': 'Failed to cancel the order. Cause: ',
    'total.delivery.points.sm': 'Total points',
    'delivery.to.the.point': 'Delivery to the point of issue',
    'pickup.from.stock': 'Pickup from stock',
};
const sup = {
    'preload.loading': 'Loading... ',
    'brand.label': 'Brand',
    'part.number': 'Part number',
    'answer.label': 'Answer',
    'cost.label': 'Cost',
    'delivery.time': 'Delivery time',
    'delivery.time.comma.days': 'Delivery time, days',
    'amount.of-tax': 'Amount of tax from buyer, ',
    'cost.with-tax': 'Cost with tax, ',
    'country.code': 'Country code',
    'country.name': 'Country name',
    'customs.declaration': '№ customs declaration',
    'comments.label': 'Comments',
    'lines.quantity': 'Lines quantity',
    'lines.qty': 'Lines qty',
    'invoice.abbr.number': 'Inv. No.',
    'invoice.nubmer': 'Invoice No.',
    'invoice.label': 'Invoice',
    'number.invoice': 'No. invoice',
    'simple.invoice': 'Simple invoice',
    'first-line.product.label': 'The first line with the product',
    'parts.product.label': '№ parts',
    'number.of.parts.label': 'Number of parts',
    'unit.price.label': 'Unit price',
    'cost.without.tax.label': 'Cost without tax',
    'amount.of.tax.label': 'Amount of tax',
    'country.code.label': 'Country code',
    'cargo.customs.declaration.label': '№ TBG',
    'legislative.tracking.code.label': 'legislative tracking code',
    'legislative.tracking.symbol.label': 'legislative tracking symbol',
    'legislative.tracking.qty.label': 'legislative tracking qty',
    'download.response.label': 'Download the response',
    'send.label': 'Send',
    'setting.up.schedule.label': 'Setting up a schedule',
    'mail.for.orders.label': 'Mail for orders',
    'boxes.separated.by.space.placeholder': 'multiple boxes separated by a space',
    'hours-minutes.separated.by.space.placeholder': 'enter hours: minutes separated by a space',
    'bottom.upload.invoice.label': 'Upload an invoice',
    'bottom.file.download.label': 'Download',
    'bottom.setting.label': 'Setting',
    'bottom.processing.label': 'Processing',
    'bottom.order.schedule.label': 'Order schedule',
    'loading.invoice.label': 'Loading an invoice',
    'invoice.file.label': 'Invoice file',
    'assign.invoice.cells.label': 'Assign invoice cells:',
    'assign.cells.label': 'Assign cells :',
    'not.uploaded.invoice.label': 'Not uploaded the invoice',
    'go.to.settings.label': 'Go to settings',
    'process.label': 'Process',
    'time.of-sending.label': 'The time of sending',
    'file.no-name': 'noname',
    'file.try.load.again': 'try again',
    'file.not.loaded': 'not loaded',
    //"no.data.label": "No data",
    'try.again.label': 'Try again',
    'file.load.completed.label': 'Файл загружен, настроен и обработан.',
    'change.settings.label': 'change settings',
    'or.label': 'or',
    'file.feminine.label': 'file.',
    'line.label': 'line',
    'for.amount.label': 'For amount',
    'no.label': 'No',
    'yes.label': 'Yes',
    'downloaded.label': 'Downloaded',
    'downloaded.another.label': 'Downloaded another',
    'download.file.label': 'The file is download...',
    'file.successfully.downloaded': 'The file was download successfully',
    'upload.label': 'Upload',
    'upload.another': 'Upload another',
    'file.successfully.uploaded': 'File uploaded successfully',
    'file.is.uploading': 'File is uploading...',
    'lines.loaded': 'Lines loaded',
    'lines.successfully.loaded': 'Lines successfully loaded',
    'error.lines': 'Error lines',
    'unrecognized.brand.lines': 'Unrecognized brand lines',
    'add.total.amount.parts.to-confirm.correctness': 'Specify the total amount of parts in the file to confirm the correctness of the settings:',
    'file.have.errors.to-fix.them': 'There are errors in the file. Fix them and',
    'file.download': 'downloaded file',
    'file.download.again': 'again.',
    'amount.no-match': "The amount doesn't match.",
    'amount.matched.settings.correct': 'The amount matched - the settings are correct.',
    'file.processing.settings.saved': 'After processing the file, the settings will be saved',
    'user.can.send.files.to-email': 'You can send invoice files to your email address',
    'split.by.order.number': 'split by order number',
    'ru.rouble.label': 'Russian rouble',
    'check.settings.label': 'Check in the settings',
    //"updated.label": "Updated",
    'currency.label': 'Currency',
    'download.method.label': 'Download method',
    'manual.update.label': 'Manual update',
    'external.file.label': 'External file',
    'not.recognized.label': 'Not recognized',
    'brands.label': 'brands',
    'unrecognized.brands.label': 'Unrecognized brands',
    'compare.brands.from.price.list.label': 'Compare brands from your price list with our list',
    'compare.brands.label': 'Compare brands',
    'configure.label': 'Configure',
    'server.label': 'Server',
    'select.connection.protocol.label': 'Select the connection Protocol:',
    'address.label': 'Address',
    'port.label': 'Port',
    'port.desc': `Select the port to connect, if you don't understand, try to leaving the current value, or ask your administrator.`,
    'path.label': 'Path',
    'select.path.to.file.label': 'select path to the file, relative to the root',
    'enter.login.for.connection.label': 'Enter the login for the connection, if available.',
    'enter.password.for.connection.label': 'Enter the password for the connection, if available.',
    // 'time.label': 'Time',
    'time.pricelist.tooltip': `Enter the time of updating the price list in hours. The price list will be updated every day at this time.`,
    'price-lists.download.make.available': 'Download the price lists to make them available',
    'price-lists.add': 'Add a price list',
    'price-lists.upload.external.storage': 'Upload the file to the external storage, which specified in the settings,',
    'then.click.on.button': 'then click on the button',
    'price-lists.check.settings.send.to.email': 'To check the price list settings, send the file to your e-mail,',
    'price-lists.error': 'Error in the price list',
    'price-lists.delete': 'Delete the price list?',
    'user.details.label': 'User details',
    'contact.phone.label': 'Contact phone number',
    'download.utd.by.mail.label': 'Download of the UPD by mail',
    'address.for.send.label': 'Address for the send',
    'file.name.label': 'File name',
    'file.name.lg.label': 'File name',
    'address.for.receiving.label': 'Address for receiving',
    'company.details.label': 'Company details',
    'company.name.label': 'Company name',
    'company.ein.label': 'EIN',
    'corporate.registration.number.label': 'Corporate registration number',
    'corporate.registration.number.abbr': 'CRN',
    'bik.code.label': 'ABA Code',
    'payment.account.label': 'Payment account',
    'payment.account.abbr': 'P/a',
    'correspondent.account': 'Correspondent account',
    'correspondent.account.abbr': 'Correspondent account',
    'legal.address.label': 'Legal address',
    'legal.address.short': 'Legal address',
    'bank.label': 'Bank',
    'bank.name': 'Bank name',
    'signatory.label': 'Signatory',
    'by.proxy': 'By proxy',
    'by.proxy.abbr': 'pp.',
    'accountant.label': 'Accountant',
    'including.vat': 'including VAT',
    'set.document.numbering': 'Set document numbering',
    'prefix.label': 'Prefix',
    'start.numbering': 'Start numbering',
    'excel.format.upto.2mb': 'XLS or XLSX format, up to 2 MB.',
    'file.uploading': 'File is uploading...',
};
const wh = {
    'create.implementation': 'Create implementation',
    'create.refund': 'Create refund',
    'create.task': 'Create a task',
    'task.on assemble': 'on assemble',
    'unlock.label': 'Unlock',
    'take.off.label': 'Unlock',
    'warehouse.label': 'Warehouse',
    'placement.label': 'Placement',
    'packing.label': 'Picking',
    'crossdocking.label': 'Cross-docking',
    'element.of.receipt.label': 'Element of receipt',
    'receipt.label': 'Receipt',
    'issue.label': 'Issue',
    'issue.btn': 'Issue',
    'picking.type.label': 'Picking type',
    'shipment.label': 'Shipment',
    'stock.label': 'Stock',
    'stock.realTime.upload': 'realTime upload',
    'stock.receipt.label': 'Receipt of stock',
    'quantity.limit': 'quantity limit',
    'add.btn': 'Add',
    'added.btn': 'Added',
    'clear.btn': 'Clear',
    'cells.storage': 'cells',
    'sector.label': 'Sector',
    'floor.label': 'Floor',
    'cells.label': 'Cells',
    'transfer.label': 'Transfer',
    'select.clients.label': 'Select clients',
    'filter.label': 'Filter',
    'not.selected.label': 'not selected',
    'item.with.qr': 'Item with QR',
    'item.not.found': 'not found',
    'item.already.assembled': 'already assembled',
    // 'at.the.place': 'At the place',
    'by.places.label': 'By places',
    'blocked.label': 'Blocked',
    'module.label': 'Module',
    'no.products': 'No products',
    'start.receipt': 'start receipt',
    'block.label': 'Block',
    'assign.label': 'Assign',
    'assign.a.place': 'Assign a location',
    'select.the.cells.number': 'Select the cell number',
    'incoming.label': 'Incoming',
    'verification.label': 'Verification',
    'search.by.article': 'Search by article',
    'accepted.label': 'accepted',
    'to.accept.label': 'to accept',
    'accepted.pieces.abbr': 'Accepted pc',
    'how.many.units': 'How many units',
    'enter.quantity.label': 'Enter quantity',
    'maximum.quantity': 'maximum',
    'receipt.time.label': 'Receipt time',
    'cancel.receipt.label': 'Cancel receipt',
    'select.stock.label': 'Select stock',
    'result.btn': 'Result',
    'invoice.loaded.label': 'Invoice loaded.',
    'next.label': 'Next',
    'assign.the.quantity.and.location': 'Assign the quantity and location',
    'buyer.refusal.block.product': "Buyer's refusal, block this product",
    'item.label': 'Item',
    'spread.label': 'Spread',
    'reason.for.blocking.label': 'Reason for blocking',
    'actual.reason.label': 'The actual reason',
    'reason.change.to.label': 'Change to',
    'decision.label': 'Decision',
    'on.stock.label': 'On stock',
    defective: 'Defective',
    counterfeit: 'Counterfeit',
    'not.delivered': 'Not delivered',
    loss: 'Loss',
    refusal: 'Refusal',
    refund: 'Refund',
    'qr.code': 'QR-code',
    'disconnected.plural': 'Disconnected',
    'disabled.plural': 'Disabled',
    'shipments.label': 'Shipments',
    'create.shipment': 'Create a shipment',
    'adding.position.to.utd': 'Adding a position to UTD №',
    'edit.utd': 'Edit UTD №',
    'add.position.label': 'Add a position',
    'number.label': 'Number',
    'creation.date.label': 'Creation date',
    'default.markup': 'Default markup',
    'default.markup.comma.percent': 'Default markup, %',
    'change.margin.label': 'To change the margin',
    'change.price.label': 'To change the price',
    'change.quantity.label': 'To change the quantity',
    'download.prices.btn': 'Download prices',
    'loading.prices.label': 'Loading prices',
    'file.with.prices': 'A file with prices',
    'price.change.history.label': 'Price change history',
    'switch.on.label': 'Switch on',
    'switch.on.sm': 'on',
    'switch.off.label': 'Switch off',
    'switch.off.sm': 'off',
    'confirm.action': 'Confirm the action',
    'deselect.label': 'Deselect',
    'select.all.label': 'Select all',
    'selected.label': 'Selected',
    'download.selected.lines': 'Download selected lines',
    'shift.label': 'Shift',
    'range.label': 'Range:',
    'total.cells.label.colon': 'Total cells:',
    'this.area.label': 'This area:',
    'not.occupied.label': 'not occupied',
    'print.label': 'Print',
    'print.location.label': 'Print location',
    'specify.range.addresses.or.single.cell': 'Specify a range of addresses or a single cell',
    'run.the.application': 'Run the application',
    'download.the.app': 'Download the app.',
    'run.the.downloaded.app': 'Launch the downloaded app.',
    'select.the.printer': 'Select the printer',
    'save.the.printer': 'Save the printer',
    'by.default.label': 'by default.',
    'printer.configuration.label': 'Printer configuration',
    'from.supplier': 'From supplier',
    'empty.stock.message.part1': `Your stock is empty. For the goods to appear on stock, first upload them in the Receipt section, or follow this `,
    'empty.stock.message.part2': 'link',
    'create.place': 'Create a place',
    'enter.code.issuing': 'enter the code for issuing the order',
    'code.correct.issuing': 'the code is correct, the issuance of the order is allowed',
    'invalid.code.issuing': 'invalid issuing code!',
    'at.for.time.preposition': 'at',
    'invalid.email.address': 'Invalid email address',
    'file.name.orders': 'orders',
    'role.edit.main.info.description.info': 'Specify the role name and the corresponding access levels',
    // Receipt
    'whole.string.is.article': 'Whole string',
    'in.the.start.of.the.string.divided.by.whitespace': 'In the start of the string, divided by whitespace',
    'in.the.end.of.the.string.divided.by.whitespace': 'In the end of the string, divided by whitespace',
    'in.round.brackets.anywhere.in.the.line': 'In round brackets',
    // Placement
    'get.placement.items.request.failed': 'Get placement list error',
    'remove.blocking.label': 'Unblock',
};
const en = { ...core, ...adm, ...main, ...sup, ...wh };
// for debug. Duplicate keys search.
const findDuplicates = (arr) => {
    let sorted_arr = arr.slice().sort();
    let results = [];
    for (let i = 0; i < sorted_arr.length - 1; i++) {
        if (sorted_arr[i + 1] == sorted_arr[i]) {
            results.push(sorted_arr[i]);
        }
    }
    return results;
};
let duplicateFound = Object.keys(core).length + Object.keys(adm).length + Object.keys(main).length + Object.keys(sup).length + Object.keys(wh).length !==
    Object.keys(en).length;
if (duplicateFound) {
    var all = [];
    all.push(...Object.keys(core));
    all.push(...Object.keys(adm));
    all.push(...Object.keys(main));
    all.push(...Object.keys(sup));
    all.push(...Object.keys(wh));
    //console.log("duplicates: ", findDuplicates(all));
    findDuplicates(all);
    alert('duplicates found, check console for details');
}
export { en };
