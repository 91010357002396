import { PortionState } from 'admin/modules/orders/models';
export const getOrderStatusColorName = (stateId) => {
    switch (stateId) {
        case PortionState.Ordered:
            return 'default';
        case PortionState.SentToSupplier:
            return 'yellow';
        case PortionState.ConfirmedBySupplier:
            return 'amber';
        case PortionState.CanceledBySupplier:
            return 'orange';
        case PortionState.ExpectedInStock:
            return 'secondary'; // purple  (violet can be used also)
        case PortionState.Received:
            return 'teal';
        case PortionState.InTheStock:
            return 'info';
        case PortionState.SentToCustomer:
            return 'success';
        case PortionState.CanceledByAdministrator:
            return 'error';
        case PortionState.RefundRequested:
            return 'fuchsia';
        case PortionState.RefundApprovedAndExpectedInWarehouse:
            return 'emerald';
        case PortionState.RefundRejected:
            return 'pink';
        case PortionState.RefundAcceptedInStock:
            return 'sky';
        case PortionState.CanceledByCustomer:
            return 'rose';
        case PortionState.AcceptedAtOPP:
            return 'cyan';
        case PortionState.TransferredToDeliveryService:
            return 'success'; // green
        case PortionState.TransferredToDeliveryServiceOwn:
            return 'lime';
        default:
            return 'default';
    }
};
