import { jsx as _jsx } from "react/jsx-runtime";
import { TableCell, TableRow } from 'core/styled';
import { Typography } from '@mui/material';
const TableHeadRow = ({ columns, typographyVariant, noTopBorder, lineHeight }) => {
    return (_jsx(TableRow, { children: _jsx(TableHeadCells, { columns: columns, typographyVariant: typographyVariant, noTopBorder: noTopBorder, lineHeight: lineHeight }) }));
};
// TODO: to delete all comments later if no need.
const TableHeadCells = ({ columns, typographyVariant, noTopBorder, lineHeight }) => {
    // return columns.map(({ text, align, noWrap, span, width, maxWidth }, index) => (
    return columns.map(({ text, align, noWrap, span, width }, index) => (
    // <TableCell key={index} colSpan={span} width={width} sx={{ maxWidth: maxWidth, borderTop: noTopBorder ? 0 : undefined }}>
    _jsx(TableCell, { colSpan: span, width: width, sx: { borderTop: noTopBorder ? 0 : undefined }, children: _jsx(Typography, { variant: typographyVariant || 'body2', fontSize: "inherit", fontWeight: (theme) => theme.typography.fontWeightMedium, align: align, noWrap: noWrap, lineHeight: lineHeight, children: text }) }, index)));
};
export { TableHeadRow, TableHeadCells };
