import { createSlice } from '@reduxjs/toolkit';
import { UploadMethod } from 'admin/modules/suppliers/models';
const initialState = {
    searchTerm: '',
    showDisabledSuppliers: false,
    supplierAddDialog: {
        open: false,
    },
    supplierEditDialog: {
        open: false,
        supplierId: undefined,
    },
    supplierPriceListEditDialog: {
        open: false,
        supplierId: undefined,
        supplierPriceListId: undefined,
        supplierIsStock: undefined,
        fileState: {
            success: true,
            method: UploadMethod.Manual,
        },
        newFileUploaded: false,
    },
    unrecognizedBrandsDialog: {
        open: false,
        priceListLogo: undefined,
        unrecognizedBrands: undefined,
        refreshUnsavedBrands: undefined,
        refreshUnsavedBrandsIsFetching: false,
    },
    blacklistEditDialog: {
        open: false,
        getRequestStatus: 200,
        data: undefined,
    },
};
const supplierSlice = createSlice({
    name: 'supplier',
    initialState,
    reducers: {
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        },
        setShowDisabledSuppliers: (state, action) => {
            state.showDisabledSuppliers = action.payload;
        },
        openSupplierEditDialog: (state, action) => {
            state.supplierEditDialog.open = true;
            state.supplierEditDialog.supplierId = action.payload.supplierId;
        },
        closeSupplierEditDialog: (state) => {
            state.supplierEditDialog.open = false;
            state.supplierEditDialog.supplierId = undefined;
        },
        openSupplierAddDialog: (state) => {
            state.supplierAddDialog.open = true;
        },
        closeSupplierAddDialog: (state) => {
            state.supplierAddDialog.open = false;
        },
        openSupplierPriceListEditDialog: (state, action) => {
            // Immer fails here for some reason with message: Cannot assign to read only property 'success' of object '#<Object>'.
            // That's why destructuring has to be used here.
            state.supplierPriceListEditDialog = {
                ...initialState.supplierPriceListEditDialog,
                ...action.payload,
                open: true,
                fileState: {
                    ...initialState.supplierPriceListEditDialog.fileState,
                    success: action.payload.supplierPriceListId ? true : false,
                },
            };
        },
        closeSupplierPriceListEditDialog: (state) => {
            state.supplierPriceListEditDialog = initialState.supplierPriceListEditDialog;
        },
        setSupplierPriceListEditDialogFileState: (state, action) => {
            state.supplierPriceListEditDialog.fileState.success = action.payload.success ?? true;
            state.supplierPriceListEditDialog.fileState.method = action.payload.method;
            if (action.payload.success === true) {
                state.supplierPriceListEditDialog.newFileUploaded = true;
            }
        },
        openUnrecognizedBrandsDialog: (state, action) => {
            state.unrecognizedBrandsDialog = { ...action.payload, open: true };
        },
        closeUnrecognizedBrandsDialog: (state) => {
            state.unrecognizedBrandsDialog.open = false;
            state.unrecognizedBrandsDialog.priceListLogo = undefined;
            state.unrecognizedBrandsDialog.refreshUnsavedBrands = undefined;
            // Must NOT be 'undefined', but an empty array, not to remove dialog from DOM on close.
            state.unrecognizedBrandsDialog.unrecognizedBrands = [];
        },
        openBlacklistDialog: (state, action) => {
            state.blacklistEditDialog.open = true;
            state.blacklistEditDialog.data = action.payload;
        },
        closeBlacklistDialog: (state) => {
            state.blacklistEditDialog = initialState.blacklistEditDialog;
        },
        setGetBlacklistQueryResponseStatus: (state, action) => {
            state.blacklistEditDialog.getRequestStatus = action.payload;
        },
    },
});
export const { actions: supplierActions, reducer: supplierReducer } = supplierSlice;
