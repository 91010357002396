import { PortionState } from 'admin/modules/orders/models';
// TODO: ! чекнуть где используются эти цвета (так же чекнуть палитру)
const getOrderStatusColor = (stateId, theme) => {
    const { ordered, canceledBySupplier, expectedInWarehouse, sentToCustomer, sentToSupplier, canceledByAdmin, inWarehouse } = theme.palette.orderStatus;
    switch (stateId) {
        case PortionState.Ordered:
            // TODO: simplify to { ...ordered}
            return { link: ordered.link, linkHover: ordered.linkHover, chipLabel: ordered.chipLabel, chipBackground: ordered.chipBackground };
        // 2: "Отправлен поставщику",
        case 2:
            return {
                link: sentToSupplier.link,
                linkHover: sentToSupplier.linkHover,
                chipLabel: sentToSupplier.chipLabel,
                chipBackground: sentToSupplier.chipBackground,
            };
        // 5: "Отменен поставщиком",
        case 5:
            //return { link: theme.palette.error.main, linkHover: theme.palette.error.light };
            return {
                link: canceledBySupplier.link,
                linkHover: canceledBySupplier.linkHover,
                chipLabel: canceledBySupplier.chipLabel,
                chipBackground: canceledBySupplier.chipBackground,
            };
        // 6: "Ожидается на складе testtestov",
        case 6:
            //return { link: theme.palette.grey[500], linkHover: theme.palette.grey[600] };
            return {
                link: expectedInWarehouse.link,
                linkHover: expectedInWarehouse.linkHover,
                chipLabel: expectedInWarehouse.chipLabel,
                chipBackground: expectedInWarehouse.chipBackground,
            };
        // 8: "На складе testtestov",
        case 8:
            return {
                link: inWarehouse.link,
                linkHover: inWarehouse.linkHover,
                chipLabel: inWarehouse.chipLabel,
                chipBackground: inWarehouse.chipBackground,
            };
        // 9: "Отправлен заказчику",
        case 9:
            //return { link: theme.palette.success.dark, linkHover: theme.palette.success.main };
            return {
                link: sentToCustomer.link,
                linkHover: sentToCustomer.linkHover,
                chipLabel: sentToCustomer.chipLabel,
                chipBackground: sentToCustomer.chipBackground,
            };
        // 12: "Отменен администратором",
        case 12:
            //return { link: theme.palette.error.darker, linkHover: theme.palette.error.dark };
            return {
                link: canceledByAdmin.link,
                linkHover: canceledByAdmin.linkHover,
                chipLabel: canceledByAdmin.chipLabel,
                chipBackground: canceledByAdmin.chipBackground,
            };
        // 4: "Подтвержден поставщиком",
        // 7: "Получен",
        // 13: "Запрошен возврат",
        // 14: "Возврат одобрен, ожидается на складе",
        // 15: "Возврат отклонен",
        // 16: "Возврат принят на складе",
        // 17: "Отменен заказчиком",
        // 18: "Принят в ПВЗ",
        // 19: "Передан в службу доставки",
        // 20: "Передан в службу доставки (собств.)"
        default:
            return {
                link: theme.palette.text.primary,
                linkHover: theme.palette.text.muted,
                chipLabel: theme.palette.text.light,
                chipBackground: theme.palette.background.default,
            };
    }
};
export { getOrderStatusColor };
