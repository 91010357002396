import { alpha } from '@mui/material';
export const shadowSm = '0 1px 2px 0 rgb(0 0 0 / 0.05);';
export const getButtonAndInputBoxShadow = ({ palette }) => `rgb(255, 255, 255) 0px 0px 0px 0px inset, ${palette.grey[300]} 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px`;
export const getButtonAndInputBoxShadowFocused = ({ palette }) => `rgb(255, 255, 255) 0px 0px 0px 0px inset, ${palette.primary.dark} 0px 0px 0px 2px inset, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px`;
// export const buttonAndInputBoxShadowDisabled =
// 	'rgb(255, 255, 255) 0px 0px 0px 0px, rgb(229, 231, 235) 0px 0px 0px 1px, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px';
// export const getButtonAndInputBoxShadowDisabled = ({ palette }: Theme) =>
// 	`rgb(255, 255, 255) 0px 0px 0px 0px, ${palette.grey[200]} 0px 0px 0px 1px, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px`;
export const getErrorInputBoxShadow = ({ palette }) => `#fff 0px 0px 0px 0px inset, ${palette.error.lighter} 0px 0px 0px 1px inset, rgba(0, 0, 0, 0) 0px 0px 0px 0px`;
export const getErrorInputBoxShadowFocused = ({ palette }) => `rgb(255, 255, 255) 0px 0px 0px 0px inset, ${palette.error.main} 0px 0px 0px 2px inset, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px`;
/** Obsolete */
export const pagePaperShadow = 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.1) 0px 1px 2px -1px';
// TODO: (later) rename to `getPagePaperShadow`, when new Catalyst styles are fully applied.
export const pagePaperShadowZinc = `rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(9, 9, 11, 0.07) 0px 0px 0px 1px, rgba(9, 9, 11, 0.05) 0px 2px 2px 0px`;
export const getPagePaperShadowZinc = ({ palette }) => `rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, ${alpha(palette.text.primary, 0.07)} 0px 0px 0px 1px, ${alpha(palette.text.primary, 0.05)} 0px 2px 2px 0px`;
// Initial Catalyst variant
// 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(9, 9, 11, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px'
// Shifts and strengthens shadow for MuiPopup-paper
export const popupPaperShadow = '#fff 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 1px 15px -3px, rgba(0, 0, 0, 0.1) 0px 4px 10px 2px';
export const shadows = [
    // shadow 0
    'none',
    // shadow 1
    shadowSm, // tailwind shadow-sm
    // shadow 2
    '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);', // tailwind shadow
    // shadow 3
    '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);', // tailwind shadow-md
    // shadow 4
    '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);', // tailwind shadow-lg (used as simple Menu shadow)
    // shadow 5
    '0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);', // tailwind shadow-xl
    // shadow 6
    '0 25px 50px -12px rgb(0 0 0 / 0.25);', // tailwind shadow-2xl
    // shadow 7
    'inset 0 2px 4px 0 rgb(0 0 0 / 0.05);', // shadow-inner
    // shadow 8
    '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);', // tailwind shadow-md MUI uses '-elevation8' class for popovers menus.
    // shadow 9
    '',
    // shadow 10
    // pagePaperShadow,
    pagePaperShadowZinc,
    // shadow 11
    popupPaperShadow,
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    // not working solution because of TS
    //...createTheme({}).shadows.slice(7).map((shadow, i) => shadow), // other native mui shadows without first 7 shadows.
    //...createTheme({}).shadows.slice(7), // other native mui shadows without first 7 shadows.
];
