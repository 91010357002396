import { TableRow as MuiTableRow, styled, tableCellClasses } from '@mui/material';
import { shouldNotForwardProps } from 'shared/helpers';
/**
 * **Global Parts** `TableRow` component.
 */
export const TableRow = styled(MuiTableRow, shouldNotForwardProps('isGroupHeader'))(({ theme, isGroupHeader }) => ({
    ...(isGroupHeader && {
        [`.${tableCellClasses.root}`]: {
            backgroundColor: theme.palette.background.light,
        },
    }),
}));
