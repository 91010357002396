import { shouldNotForwardProps } from 'shared/helpers';
import { styled } from '@mui/material';
export const Row = styled('div', shouldNotForwardProps('gap', 'alignItems', 'justifyContent', 'paddingBottom', 'paddingTop'))(({ theme, gap, alignItems, justifyContent, paddingTop, paddingBottom }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: gap === 0 ? undefined : gap ? (typeof gap === 'number' ? `${gap / 2}rem` : gap) : '1.5rem',
    alignItems,
    justifyContent: justifyContent,
    paddingTop,
    paddingBottom,
}));
