import { Table as MuiTable, alpha, styled, tableCellClasses, tableRowClasses } from '@mui/material';
import { shouldNotForwardProps } from 'shared/helpers';
const defaultSideCellSpacing = 1;
export const Table = styled(MuiTable, shouldNotForwardProps('striped', 'sideCellSpacing'))(({ theme, striped, sideCellSpacing = defaultSideCellSpacing }) => ({
    // '.MuiTableCell-root': {
    [`.${tableCellClasses.root}`]: {
        /// color: theme.palette.text.muted,
        // padding: '.25rem 0.875rem', // 4px 14px
        ':first-of-type': {
            paddingLeft: theme.spacing(sideCellSpacing),
        },
        ':last-of-type': {
            paddingRight: theme.spacing(sideCellSpacing),
        },
    },
    ...(striped && {
        [`.${tableRowClasses.root}`]: {
            [`&:nth-of-type(${striped === 'odd' ? 'odd' : 'even'})`]: {
                // backgroundColor: theme.palette.action.hover,
                backgroundColor: alpha(theme.palette.text.primary, 0.025),
            },
            '&:not(:first-of-type)': {
                [`.${tableCellClasses.root}`]: {
                    borderTop: 'none',
                },
            },
        },
    }),
}));
