// TODO: later use universal functions for multiple languages when new languages are needed.
export const enUsCurrencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
const quantityFormatter = new Intl.NumberFormat('ru-RU', {});
export const formatQuantity = (value) => quantityFormatter.format(value);
const percentFormatter = new Intl.NumberFormat('ru-RU', {
    style: 'percent',
    // minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});
export const formatPercent = (value) => percentFormatter.format(value / 100);
// Uses Intl.NumberFormat under the hood
// export const toLocaleCurrency = (value: number, minimumFractionDigits: number = 2) => {
// 	return value.toLocaleString('ru-RU', {
// 		style: 'currency',
// 		currency: 'RUB',
// 		minimumFractionDigits,
// 	});
// };
const currencyFormatter = new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    // Removes '.00 $' from result.
    trailingZeroDisplay: 'stripIfInteger',
});
const currencyFormatterWithZeros = new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});
/**
 * Sign (`$`) wont be shown if `style: 'currency'` is removed `
 */
const compactCurrencyFormatter = new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB', notation: 'compact' });
export function formatCurrency(value, options) {
    return options?.compact
        ? compactCurrencyFormatter.format(value)
        : options?.alwaysShowFractionDigits
            ? currencyFormatterWithZeros.format(value)
            : currencyFormatter.format(value);
}
/* TODO: To be updated based on need - French-Canada and US locale handled  */
export const localeToCurrency = {
    'ru-RU': 'RUB',
    'en-US': 'USD',
    'fr-CA': 'CAD',
    'hi-IND': 'INR',
};
