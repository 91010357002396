import { jsx as _jsx } from "react/jsx-runtime";
import { Typography, styled } from '@mui/material';
import { shouldNotForwardProps } from 'shared/helpers';
import { useCopyToClipboard } from 'usehooks-ts';
const CopyTypography = (props) => {
    const [copiedText, copy] = useCopyToClipboard();
    const handleClick = () => {
        const { children } = props;
        if (!children || !(typeof children === 'string' || typeof children === 'number'))
            return;
        copy(`${children}`);
    };
    return _jsx(TypographyStyled, { ...props, onClick: handleClick });
};
const TypographyStyled = styled(Typography, shouldNotForwardProps('cursor', 'hoverColor'))(({ theme, cursor, hoverColor }) => ({
    fontSize: 'inherit',
    ':hover': {
        cursor: cursor ?? 'inherit',
        // TODO: workaround (textMedium not working, expected color hex)
        color: hoverColor === 'textMedium' ? theme.palette.text.medium : theme.palette.primary.dark,
    },
}));
export { CopyTypography };
