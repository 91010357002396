import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { LocalizationProvider, ruRU } from '@mui/x-date-pickers';
import { StyledEngineProvider, ThemeProvider, createTheme } from '@mui/material/styles';
import { getPalette, themeOptions } from 'core/theme';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { ConfirmDialogProvider } from 'core/hooks';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import { RouterProvider } from 'react-router-dom';
import { SimpleSnackbar } from 'shared/components';
import { SnackbarProvider } from 'notistack';
import { router } from './navigation';
import { ru } from 'date-fns/locale/ru';
import { setDefaultOptions } from 'date-fns/setDefaultOptions';
import { useMemo } from 'react';
setDefaultOptions({ locale: ru });
const mode = 'light';
const russianLocaleText = ruRU.components.MuiLocalizationProvider.defaultProps.localeText;
export const App = () => {
    const theme = useMemo(() => createTheme({ ...themeOptions, ...getPalette(mode), cssVariables: { cssVarPrefix: 'gp' } }), []);
    return (_jsx(_Fragment, { children: _jsx(LocalizationProvider, { dateAdapter: AdapterDateFns, adapterLocale: ru, localeText: russianLocaleText, children: _jsx(StyledEngineProvider, { injectFirst: true, children: _jsxs(ThemeProvider, { theme: theme, children: [_jsx(CssBaseline, {}), _jsx(GlobalStyles, { styles: {
                                html: {
                                    height: '100%',
                                    // fontFeatureSettings: `'cv02', 'cv03', 'cv04', 'cv11'`. Tailwind font features. 'cv11' - different 'a' letter
                                    fontFeatureSettings: `'cv02', 'cv03', 'cv04'`,
                                },
                                body: {
                                    height: '100%',
                                },
                                '#root': {
                                    minHeight: '100%',
                                },
                                // Original fontawesome classes
                                '@keyframes fa-spin': {
                                    '0%': {
                                        transform: 'rotate(0deg)',
                                    },
                                    '100%': {
                                        transform: 'rotate(360deg)',
                                    },
                                },
                                '.fa-spin': {
                                    animationName: 'fa-spin',
                                    animationDelay: 'var(--fa-animation-delay, 0s)',
                                    animationDirection: 'var(--fa-animation-direction, normal)',
                                    animationDuration: 'var(--fa-animation-duration, 2s)',
                                    animationIterationCount: 'var(--fa-animation-iteration-count, infinite)',
                                    animationTimingFunction: 'var(--fa-animation-timing, linear)',
                                },
                                // Removes new Chrome feature with black outline on :focus-visible
                                ':focus-visible': {
                                    // outline: '-webkit-focus-ring-color auto 2px',
                                    outline: 'none',
                                },
                                '&.Mui-focusVisible': {
                                    outlineColor: theme.palette.primary.dark,
                                    outlineOffset: '2px',
                                    outlineWidth: '2px',
                                    outlineStyle: 'solid',
                                    zIndex: theme.zIndex.fab,
                                },
                                // Remove new Chrome 83 feature with black outline on :focus-visible
                                // https://developer.chrome.com/blog/new-in-chrome-83/#forms
                                // '*,*:focus,*:hover,*:focus-visible,*:active': {
                                // 	outline: 'none',
                                // },
                                // '.MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected="true"]': {
                                // 	background: 'dodgerblue', // change accordingly
                                // 	color: 'white', // change accordingly
                                // },
                                // '.MuiAutocomplete-popper .MuiAutocomplete-option[role="option"][aria-selected="false"]:hover': {
                                // 	//background: rgba(0, 0, 0, 0.1); // change accordingly
                                // 	background: 'lightgrey', // change accordingly
                                // },
                            } }), _jsx(SnackbarProvider, { anchorOrigin: { horizontal: 'right', vertical: 'bottom' }, Components: {
                                default: SimpleSnackbar,
                                success: SimpleSnackbar,
                                info: SimpleSnackbar,
                                warning: SimpleSnackbar,
                                error: SimpleSnackbar,
                            } }), _jsx(ConfirmDialogProvider, { children: _jsx(RouterProvider, { router: router }) })] }) }) }) }));
};
