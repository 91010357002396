export function propertyOf(name) {
    return name;
}
export function propertiesOf(obj) {
    return new Proxy({}, {
        get: (_, prop) => prop,
        set: () => {
            throw Error('Set not supported');
        },
    });
}
